import React, { Component } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import {
  Alert,
  Border,
  FormHeading,
  ProductRow,
  Button,
  Copy,
} from "@lucio-erasmus/tfgj-components";
import { formatDate, formatPrice } from "../../helpers/format";
import { CSVLink } from "react-csv";
import { compose } from "recompose";
import { getProductConfig, getConfigurationOptions } from "../../helpers/utils";

const csvHeaders = [
  { label: "sku", key: "sku" },
  { label: "group", key: "group" },
  { label: "name", key: "name" },
  { label: "id", key: "id" },
  { label: "value", key: "value" },
];
const EntryTotal = (props) => {
  const { onSale, salesPrice, itemPrice } = props.total;
  if (!itemPrice) {
    return null;
  }
  const itemPriceText = formatPrice(itemPrice);
  if (onSale) {
    return (
      <div>
        <div>
          <strike>R{itemPriceText}</strike>
        </div>{" "}
        R{formatPrice(salesPrice)}
      </div>
    );
  }

  return <div>R{itemPriceText}</div>;
};

class EditOrderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delete: false,
      sent: false,
      loading: false,
    };
  }
  componentDidMount() {}

  updateOrder = (obj) => {
    this.setState({
      sent: false,
    });

    this.props
      .updateOrder({
        variables: {
          id: this.props.match.params.orderId,
          input: {
            ...obj,
          },
        },
      })
      .then(() => {
        this.setState({
          sent: true,
        });
      });
  };

  getImage(e, url, name) {
    e.preventDefault();
    fetch(url)
      // 1. Convert the data into 'blob'
      .then((response) => response.blob())
      .then((blob) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        alert(error);
      });
  }

  resendOrder = () => {
    this.setState({
      sent: false,
    });

    this.props
      .resendOrder({
        variables: {
          id: this.props.match.params.orderId,
        },
      })
      .then(() => {
        this.setState({
          sent: true,
        });
      });
  };

  getStore(order) {
    const { store = null, shippingAddress = null } = order;

    if (store) {
      return {
        store: store,
        storeType: "old-store",
      };
    }

    if (shippingAddress) {
      const { store: shippingStore } = shippingAddress;

      if (!shippingStore) {
        return {
          store: null,
          storeType: "no-store",
        };
      } else {
        return {
          store: shippingStore,
          storeType: "new-store",
        };
      }
    }

    return { storeType: "no-store", store: null };
  }

  showShippingAddress(order) {
    const { shippingAddress = {} } = order;

    if (shippingAddress && Object.keys(shippingAddress).length) {
      const { addressLine1, city, email } = shippingAddress;

      if (addressLine1 || city || email) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  render() {
    if (this.props.data.loading) {
      return <p>Loading</p>;
    }

    if (this.props.data.error) {
      this.props.data.error.graphQLErrors.forEach(({ message }, i) => {
        if (message === "Unauthorized action.") {
          this.props.history.push("/login");
        }
      });
      return <div>An unexpected error occurred</div>;
    }

    const {
      data: { adminOrder: order },
    } = this.props;

    const { storeType, store } = this.getStore(order);

    const showShippingAddress = this.showShippingAddress(order);

    console.log("3333", order);

    if (this.state.delete)
      return <div className="alert alert-warning">Page Deleted</div>;

    return (
      <div className="row">
        <div className="col-md-9">
          {this.state.sent ? (
            <Alert status="success">Order Updated</Alert>
          ) : null}
          {this.state.error ? (
            <Alert status="error">Error has occured, please try again.</Alert>
          ) : null}
          <div className="mb-4">
            <div className="row">
              <div className="col-md-4">
                <FormHeading>Order Details</FormHeading>
                <Border color="white" mt="small" mb="small" />
                <Copy mb={"small"}>
                  <strong>Order Number: </strong>
                  <br />
                  <span>{order.orderNumber}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Order Status: </strong>
                  <br />
                  <select
                    value={order.status}
                    className="form-control"
                    name="status"
                    onChange={(e) =>
                      this.updateOrder({ [e.target.name]: e.target.value })
                    }
                  >
                    <option />
                    <option value="approved">Approved</option>
                    <option value="cancel">Cancelled</option>
                    <option value="pending">Pending</option>
                    <option value="partial">Partial</option>
                    <option value="paid">Paid</option>
                    <option value="error">Error</option>
                  </select>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Order Date: </strong>
                  <br />
                  <span>{formatDate(order.formatDate)}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Order Payment: </strong>
                  <br />
                  <span>{order.payment}</span>
                </Copy>
                {order.status === "partial" ? (
                  <>
                    <Copy mb={"small"}>
                      <strong>Total paid: </strong>
                      <br />
                      <span>{`R${order.totals - order.due}`}</span>
                    </Copy>
                    <Copy mb={"small"}>
                      <strong>Total due: </strong>
                      <br />
                      <span>{`R${order.due}`}</span>
                    </Copy>
                  </>
                ) : null}
              </div>
              <div className="col-md-4">
                <FormHeading>Customer Details</FormHeading>
                <Border color="white" mt="small" mb="small" />
                <Copy mb={"small"}>
                  <strong>Name: </strong>
                  <br />
                  <span>{order.details.name}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Surname: </strong>
                  <br />
                  <span>{order.details.surname}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Email: </strong>
                  <br />
                  <span>{order.details.email}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Phone: </strong>
                  <br />
                  <span>{order.details.phone}</span>
                </Copy>
              </div>

              {storeType === "old-store" && (
                <div className="col-md-4">
                  <FormHeading>Store Details old</FormHeading>
                  <Border color="white" mt="small" mb="small" />
                  <Copy mb={"small"}>
                    <strong>Name: </strong>
                    <br />
                    <span>{store.name}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Address: </strong>
                    <br />
                    <span>{store.address}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Email: </strong>
                    <br />
                    <span>{store.email}</span>
                  </Copy>
                </div>
              )}
              {storeType === "new-store" && (
                <div className="col-md-4">
                  <FormHeading>Store Details new</FormHeading>
                  <Border color="white" mt="small" mb="small" />
                  <Copy mb={"small"}>
                    <strong>Name: </strong>
                    <br />
                    <span>{store.name}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Address: </strong>
                    <br />
                    <span>{store.address}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Email: </strong>
                    <br />
                    <span>{store.email}</span>
                  </Copy>
                </div>
              )}
            </div>
          </div>

          {order.shipping_method &&
            order.shipping_method.code &&
            order.shipping_method.description && (
              <div className="row">
                <div className="col-md-12">
                  <FormHeading>Shipping Details</FormHeading>
                  <Border color="white" mt="small" mb="small" />
                  <Copy mb={"small"}>
                    <strong>Code: </strong>
                    <br />
                    <span>{order.shipping_method.code}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Description: </strong>
                    <br />
                    <span>{order.shipping_method.description}</span>
                  </Copy>
                  {order.shipping_method.code !== "free" &&
                    order.shipping_method.code !== "collection" && (
                      <Copy mb={"small"}>
                        <strong>Shipping price: </strong>
                        <br />
                        <span>{`R${order.shipping_price}`}</span>
                      </Copy>
                    )}
                </div>
              </div>
            )}

          {showShippingAddress && (
            <div className="row">
              <div className="col-md-12">
                <FormHeading>Shipping Address</FormHeading>
                <Border color="white" mt="small" mb="small" />
                <Copy mb={"small"}>
                  <strong>Address line 1: </strong>
                  <br />
                  <span>{order.shippingAddress.addressLine1}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Suburb: </strong>
                  <br />
                  <span>{order.shippingAddress.suburb}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>City: </strong>
                  <br />
                  <span>{order.shippingAddress.city}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Province: </strong>
                  <br />
                  <span>{order.shippingAddress.province}</span>
                </Copy>

                <Copy mb={"small"}>
                  <strong>Postal code: </strong>
                  <br />
                  <span>{order.shippingAddress.postalCode}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Email: </strong>
                  <br />
                  <span>{order.shippingAddress.email}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Name: </strong>
                  <br />
                  <span>{`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}</span>
                </Copy>
                <Copy mb={"small"}>
                  <strong>Phone: </strong>
                  <br />
                  <span>{order.shippingAddress.phoneNumber}</span>
                </Copy>
              </div>
            </div>
          )}

          {order.paymentType === "SNAPSCAN" && order.transaction ? (
            <div>
              <div className="row">
                <div className="col-md">
                  <FormHeading>SnapScan Details</FormHeading>
                  <Border color="white" mt="small" mb="small" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Copy mb={"small"}>
                    <strong>Id: </strong>
                    <br />
                    <span>{order.transaction.id}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Status: </strong>
                    <br />
                    <span>{order.transaction.status}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Date: </strong>
                    <br />
                    <span>{order.transaction.date}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Total amount: </strong>
                    <br />
                    <span>{order.transaction.totalAmount}</span>
                  </Copy>
                </div>
                <div className="col-md-4">
                  <Copy mb={"small"}>
                    <strong>Tip amount: </strong>
                    <br />
                    <span>{order.transaction.tipAmount}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Required amount: </strong>
                    <br />
                    <span>{order.transaction.requiredAmount}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Snap code: </strong>
                    <br />
                    <span>{order.transaction.snapCode}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Snap code reference: </strong>
                    <br />
                    <span>{order.transaction.snapCodeReference}</span>
                  </Copy>
                </div>
                <div className="col-md-4">
                  <Copy mb={"small"}>
                    <strong>User reference: </strong>
                    <br />
                    <span>{order.transaction.userReference}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Merchant reference: </strong>
                    <br />
                    <span>{order.transaction.merchantReference}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Statement reference: </strong>
                    <br />
                    <span>{order.transaction.statementReference}</span>
                  </Copy>
                  <Copy mb={"small"}>
                    <strong>Auth code: </strong>
                    <br />
                    <span>{order.transaction.authCode}</span>
                  </Copy>
                </div>
              </div>
            </div>
          ) : null}

          <FormHeading>Order Summary</FormHeading>
          {order &&
            order.lineitems.map((entry) => {
              const product = getProductConfig(
                entry.configuration,
                getConfigurationOptions(entry.product.configuration.components)
              );

              return (
                <ProductRow
                  title={entry.product.name}
                  total={<EntryTotal total={entry.total} />}
                  renderMeta={() => (
                    <div>
                      {product
                        .filter((c) => c.sku !== "UPLOAD-IMG")
                        .map((c, index) => {
                          return (
                            <Copy size="small" mb={"none"}>
                              {c.group}: {c.value || c.name}
                              {c.font ? ` - ${c.font}` : null}{" "}
                              {c.languageLabel ? ` - ${c.languageLabel}` : null}{" "}
                            </Copy>
                          );
                        })}
                    </div>
                  )}
                  image={entry.imageUrl}
                />
              );
            })}

          <FormHeading>Downloads</FormHeading>
          <Border color="white" mt="small" mb="small" />
          <Copy>
            {order &&
              order.lineitems.map((entry) => (
                <div>
                  {entry.images && entry.images
                    ? entry.images.map((i) => (
                        <div>
                          <a
                            href={`${process.env.REACT_APP_PRODUCT}${i._id}`}
                            onClick={(e) =>
                              this.getImage(
                                e,
                                `${process.env.REACT_APP_PRODUCT}${i._id}`,
                                i.filename
                              )
                            }
                          >
                            {`Download ${i.filename}`}
                          </a>
                          <br />
                        </div>
                      ))
                    : null}
                  <br />
                  <CSVLink data={entry.configuration} headers={csvHeaders}>
                    Download configuration csv
                  </CSVLink>
                </div>
              ))}
          </Copy>

          <FormHeading>Order Total - R{order.totals}</FormHeading>
        </div>
        <div className="col-md-4 mt-4">
          <Button onClick={this.resendOrder}>Resend Order Emails</Button>
        </div>
      </div>
    );
  }
}

export const NAME_PARTS_FRAGMENT = gql`
  fragment NameParts on ConfigComponent {
    type
    name
    description
    note
    empty
    sku
    parentSku
    query
    price
    groups
    group
    required
    hasPadding
    multiple
    limit
    allUpcase
    text
    fonts {
      id
      title
      image
    }
    specialChars {
      type
      name
      char
    }
    languages {
      id
      language
      code
    }
    regEx
    className
    items {
      name
      group
      image
      description
      assetImage
      id
      sku
      parentSku
      price
      priceCategory
      exclusions {
        key
        values
      }
    }
  }
`;

const OrderQuery = gql`
  ${NAME_PARTS_FRAGMENT}
  query OrderQuery($id: ID!) {
    adminOrder(id: $id) {
      _id
      totals
      status
      orderNumber
      due
      transaction {
        id
        status
        date
        totalAmount
        tipAmount
        requiredAmount
        snapCode
        snapCodeReference
        userReference
        merchantReference
        statementReference
        authCode
        extra
        deliveryAddress
      }
      details {
        name
        surname
        email
        phone
      }
      store {
        name
        address
        email
        branch
      }
      payment
      formatDate
      payment_method {
        gateway
        redirect
        external
      }
      shipping_price
      paymentType
      shipping_method {
        code
        description
      }
      shippingAddress {
        firstName
        lastName
        phoneNumber
        email
        addressLine1
        suburb
        city
        postalCode
        province
        store {
          branch
          name
          address
          province
          country
          email
        }
        notes
      }
      lineitems {
        id
        productId
        imageUrl
        images {
          _id
          type
        }
        product {
          name
          slug
          featuredImage {
            url
            hotspot
            hasImage
          }
          configuration {
            id
            components {
              ...NameParts
              options {
                ...NameParts
                styles {
                  ...NameParts
                }
                items {
                  name
                  group
                  image
                  description
                  assetImage
                  id
                  sku
                  parentSku
                  price
                  priceCategory
                  exclusions {
                    key
                    values
                  }
                  styles {
                    ...NameParts
                  }
                }
              }
              styles {
                ...NameParts
              }
              items {
                name
                group
                image
                description
                assetImage
                id
                sku
                parentSku
                price
                priceCategory
                exclusions {
                  key
                  values
                }
                styles {
                  ...NameParts
                }
              }
            }
          }
        }
        configuration {
          configId
          sku
          price
          id
          image
          type
          font
          language
          priceCategory
          specification
          exclusions {
            key
            values
          }
          description
          assetImage
          group
          name
          value
        }
        total {
          discount
          itemPrice
          onSale
          salesPrice
        }
      }
    }
  }
`;

const updateOrderMutation = gql`
  mutation updateOrder($input: OrderUpdateInput!, $id: ID!) {
    updateOrder(id: $id, input: $input) {
      _id
      status
    }
  }
`;

const resendOrderMutation = gql`
  mutation resendOrder($id: ID!) {
    resendOrder(id: $id)
  }
`;

EditOrderContainer.fragments = {
  components: gql`
    fragment NameParts on ConfigComponent {
      type
      name
      description
      note
      empty
      sku
      parentSku
      query
      price
      groups
      group
      required
      hasPadding
      multiple
      limit
      allUpcase
      text
      fonts {
        id
        title
        image
      }
      specialChars {
        type
        name
        char
      }
      languages {
        id
        language
        code
      }
      regEx
      className
      items {
        name
        group
        image
        description
        assetImage
        id
        sku
        parentSku
        price
        priceCategory
        exclusions {
          key
          values
        }
      }
    }
  `,
};

export default compose(
  graphql(OrderQuery, {
    options: (ownProps) => {
      return {
        variables: {
          id: ownProps.match.params.orderId,
        },
      };
    },
  }),
  graphql(resendOrderMutation, {
    name: "resendOrder",
  }),
  graphql(updateOrderMutation, {
    name: "updateOrder",
  })
)(EditOrderContainer);
